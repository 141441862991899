import './Home.css'

export default function Home() {
  return (
    <>
      <div style={{marginLeft:'20px', marginRight: '20px', marginBottom: '50px', textAlign: 'center'}} className='homeBody'>
        <h1 className="title"> Thank you, and here's to what's next </h1>
        <div style={{textAlign:'left'}}>
          {/* <h2 className="home-subtitle">
            Then, Now, and the Future
          </h2> */}
          <div className='brief'>
            <p>
            Ovvio came to life during the pandemic; a time when meaningful human connections felt more important than ever. 
            Together, we built a space where advice, ideas, and support could flow freely.
            </p>
          </div>
          <div className='brief'>
            <p>
              But as the world has moved forward, so have the ways we interact with technology.
            </p>
          </div>
          <div className='brief'>
            <p>
            While we deeply believe in the power of human connection, 
            we’ve come to realise that something is missing and 
            the Ovvio app isn’t fully aligned with how people connect and engage today.
            </p>
          </div>
          <div className='brief'>
            <p>
              After much reflection, we’ve made the difficult decision to close our Ovvio app.
            </p>
          </div>
          <div className='brief'>
            <p>
            While this chapter is closing, it’s not the end. 
            We’re taking this time to rethink and reshape Ovvio so it can better fit the needs of today and what can follow in the future.
            </p>
          </div>
          <div className='brief'>
            <p>
              Ovvio’s technology, vision, and intellectual property are still at the core of our vision and drive our efforts to reintroduce Ovvio differently.
            </p>
          </div>
          <div className='brief' style={{fontStyle:'italic', fontWeight: '400'}}>
            <p>
              Before we go...
            </p>
          </div>
          <div className='brief'>
            <p>
            This journey so far, wouldn’t have been possible without you.
            </p>
          </div>
          <div className='brief'>
            <p>
              Thank you for being part of this community and for your trust and support. 
              We’re so grateful, and we hope to see you again when Ovvio’s next chapter begins.
            </p>
          </div>
          <div className='brief' style={{fontWeight: '400'}}>
            <p>
              With gratitude,
              <br/>
              The Ovvio Team
            </p>
          </div>
      </div>
    </div>
    </>
  )
}
