// import Faq from 'react-faq-component';
import './FAQ.css';
import { useState } from 'react';

function FAQ({faq, index, toggleFAQ}) {
    return (
      <div 
        className={"faq " + (faq.open ? 'open': '')}
        key={index}
        onClick={() => toggleFAQ(index)}
      >
        <div className='faq-title'>
          {faq.title}
        </div>
        <div className='faq-content'>
          {faq.content}
        </div>
      </div>
    )
}

export default function FAQS() {
  const [faqs, setfaqs]= useState([
      {
        title: "Will Ovvio be relaunched?",
        content: `We’re currently exploring new and exciting ways to bring the benefits of 
          our technology to life in ways that better align with our user's needs and our long-term vision. 
          Stay tuned for upcoming updates!`,
        open: false
      },
      {
        title: "What happens to my data?",
        content: `We have deleted all your data previously held by us, except for financial transaction records that 
          we are legally required to retain for up to 5 years. Additionally, there may still be intermediary copies or backups of your data,
          which are currently being deleted as part of our process. We appreciate your understanding during this time.`,
        open: false
      },
      {
        title: "Can I delete the app from my phone?",
        content: `Yes, the Ovvio app has been shut down as of 9th December, 2024. 
          Therefore, you can delete the app from your phone.`,
        open: false
      },
      {
        title: "Whom do I contact with an idea to relaunch Ovvio?",
        content: `Whether you're an investor interested in licensing our technology or a user who misses us, we’d 
          love to hear from you! Feel free to reach out through our contact form, and we’ll be happy to connect.`,
        open: false
      },
      {
        title: "I forgot to withdraw my earnings. Can I still access them?",
        content: `If you have missed the window to withdraw your funds, 
        please reach out to us using the Contact form and we will assist you as quickly as possible.`,
        open: false
      },
    ]);

  const toggleFAQ = index => {
    setfaqs(faqs.map((faq, i) => {
      if(i === index) {
        if(faq.open) {
          faq.open = false 
        } else {
          faq.open = true
        }
      }
      return faq;
    }))
  }
  // const styles = {
  //   bgColor: 'transparent',
  //   rowContentPaddingTop: '10px',
  //   rowTitleColor: "white",
  //   rowTitleTextSize: '25px',
  //   rowContentTextSize: '18px',
  //   rowContentPaddingTop: '15px',
  //   rowTitlePaddingBottom: '30px',
  //   rowContentPaddingBottom: '50px',
  //   // rowContentPaddingLeft: '0px',
  //   rowContentColor: 'white',
  //   arrowColor: "white",
  // };
  
  // const config = {
  //   // animate: true,
  //   // arrowIcon: "V",
  //   // tabFocus: true
  // };
  
  // return (
  //   <>
  //     <div>
  //       <h1 className="title" style={{paddingBottom: '50px', textAlign: 'center'}}>Frequently asked questions</h1>
  //       <div className='faq-body'>
  //         <div style={{ border: "0.1px solid #FFFFFF"}}></div>
  //         <Faq data={data} styles={styles} config={config}/>
  //       </div>
  //     </div>
  //   </>
  // )
  return (
    <>
      <div className='faq-body'>
        <div className='faqs'>
          <h1 className="faqMaintitle" style={{paddingBottom: '40px', textAlign: 'center'}}>Frequently asked questions</h1>
          {faqs.map((faq, i) => (
              <FAQ faq={faq} index={i} toggleFAQ={toggleFAQ}/>
            // </div>
          ))}
        </div>
      </div>
    </>
  )
}
