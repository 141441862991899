import { 
  Link, 
  useLocation,
  // useMatch, useResolvedPath, 
} from "react-router-dom"
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
// import { NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container'
import Offcanvas from 'react-bootstrap/Offcanvas';

import { useState, React } from 'react';
import "./Navigation.css"
import { isMobile } from "react-device-detect"
import Button from '@mui/material/Button';

export default function Navigation() {
  // const [open, setOpen] = React.useState(false);

  // const toggleDrawer = (newOpen) => () => {
  //   setOpen(newOpen);
  // };
  const location = useLocation()

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <div>
      { isMobile 
        ? 
        <div >
          <Navbar expand={'lg'} className="mb-1 mt-1">
            <Container>
              <Navbar.Brand href="/" className="navbar-brand" >
                <img className="logo" src="/images/ovvio_half_color.png" alt="Logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{border: '0px solid #ffffff'}}>
                <Button onClick={handleShow}>
                  <img className="logo" src="/images/bars1.svg" width={28} height={28} alt="Logo" style={{color: 'red'}}/>
                </Button>
              </Navbar.Toggle>
              {/* <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
              </Drawer> */}
              <Offcanvas show={show} onHide={handleClose} placement={'end'} 
                style={{width: '360px', background: '-webkit-linear-gradient(210deg, #EC008C,#41004A)', border: '0px solid #fff'}}
              >
                <Offcanvas.Header>
                <div style={{ margin: 'auto', display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '5px' }}>
                  <img className="logo" src="/images/ovvio_half_color.png" alt="Logo" width={135} height={72}/>
                </div>                  
                <div style={{marginLeft: 'auto', marginRight: '20px', marginTop: '0px'}}>
                    <img className="logo" onClick={handleClose} src="/images/xmark2.svg" width={40} height={40} color={"black"} alt="logo"/>
                  </div>
                  <Offcanvas.Title>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="mobileWrapper">
                    <Nav className="flex-grow-1 pe-3" style={{alignItems: 'left', paddingLeft: '10px'}}>
                      <Nav.Link as={Link} className={`nav-link navbarLink ${location.pathname === '/home' ? 'active' : ''}`} to="/" onClick={handleClose}>
                        {/* <div className="mobilemenuNavs"> */}
                          <span className="mobilemenuNavs"><img className="logo" src="/images/Home.svg" width={30} height={30} alt="home" style={{marginRight: '5px'}}/> HOME</span>
                        {/* </div> */}
                      </Nav.Link>
                      <Nav.Link as={Link} className={`nav-link navbarLink ${location.pathname === '/journey' ? 'active' : ''}`} to="/journey/" onClick={handleClose}>
                        <span className="mobilemenuNavs"><img className="logo" src="/images/Journey.svg" width={30} height={30} alt="home" style={{marginRight: '5px'}}/> JOURNEY</span>
                      </Nav.Link>
                      <Nav.Link as={Link} className={`nav-link navbarLink ${location.pathname === '/faq' ? 'active' : ''}`} to="/faq/" onClick={handleClose}>
                        <span className="mobilemenuNavs"><img className="logo" src="/images/FAQ.svg" width={30} height={30} alt="home" style={{marginRight: '5px'}}/> FAQ</span>
                      </Nav.Link>
                      <Nav.Link as={Link} className={`nav-link navbarLink ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact/" onClick={handleClose}>
                        <span className="mobilemenuNavs"><img className="logo" src="/images/Contact.svg" width={30} height={30} alt="home" style={{marginRight: '5px'}}/> CONTACT</span>
                      </Nav.Link>
                    </Nav>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </Container>
          </Navbar>
        </div>
        : 
        <Navbar expand={'lg'} className="mb-1 mt-1">
          <Container>
            <Navbar.Brand href="/" className="navbar-brand">
              <img className="logo" src="/images/ovvio_half_color.png" alt="Logo" />
            </Navbar.Brand> 
            <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{border: '0px solid #ffffff'}}>
              <img className="logo" src="/images/bars2.svg" width={28} height={28} alt="Logo" />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="justify-content-end flex-grow-1 pe-3" style={{marginLeft: '15px'}}>
                <Nav.Link as={Link} className={`nav-link navbarLink ${location.pathname === '/home' ? 'active' : ''}`} to="/">
                  <span>HOME</span>
                </Nav.Link>
                <Nav.Link as={Link} className={`nav-link navbarLink ${location.pathname === '/journey' ? 'active' : ''}`} to="/journey/">
                  <span>JOURNEY</span>
                </Nav.Link>
                <Nav.Link as={Link} className={`nav-link navbarLink ${location.pathname === '/faq' ? 'active' : ''}`} to="/faq/">
                  <span>FAQ</span>
                </Nav.Link>
                <Nav.Link as={Link} className={`nav-link navbarLink ${location.pathname === '/contact' ? 'active' : ''}`} to="/contact/">
                  <span>CONTACT</span>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      }
    </div>
    </>
  )
}