import Navigation from "./Navigation"
import Home from "./pages/Home"
import { Route, Routes } from "react-router-dom"
import Journey from "./pages/Journey"
import Contact from "./pages/Contact"
import FAQS from "./pages/FAQ"
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect"
import './styles.css'

function App() {
  var showBackground = false;
  const path = useLocation().pathname;
  // const location = path.split("/");
  console.log('Path', path);
  if(path === '/' || path ==='/contact/'
  ) {
    showBackground = true
  }

  return (
    <>
      { isMobile 
        ?
          <div>
            { showBackground && 
            <div className={showBackground ? 'background-circle-img' : ''} style={{paddingTop: '100px'}}>
              <img src="/images/Circle.png" alt="Background-2" className="img-fluid" />
            </div>
          }
          </div>
        : 
          <div>
            { showBackground && 
            <div className={showBackground ? 'background-circle' : ''}>
              <img src="/images/Circle.png" alt="Background-2" className="img-fluid" />
            </div>
          }
          </div>
      }
      
      <Navigation />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/journey" element={<Journey />} />
          <Route path="/faq" element={<FAQS />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </>
  )
}

export default App
