import "./Journey.css"
// import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';


const theme = createTheme({
  palette: {
    primary: {
      main: '#BFFFFFFF',
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#E0C2FF',
      light: '#F5EBFF',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#47008F',
    },
  },
});

function desktopRender() {
  return <>
    <ThemeProvider theme={theme}>
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            <div className="firstImageContent">
              <img src="/images/Journey/vision.png" style={{ height: '320px', width: '480px' }} alt="vision"/>
            </div>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector color="secondary" />
          </TimelineSeparator>
          <TimelineContent>
            <div className="firstPaneContent">
              <p style={{fontWeight: '400', fontSize: '22px'}}>A vision born (2020)</p>
              <p>
                During the pandemic, Ovvio emerged from a pressing need to reconnect people with real human expertise. 
                Co-founders Sachin and Shaalin Tandon envisioned a platform where users could find personalized support that technology alone could not provide.
              </p>
            </div>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            <div className="secondImageContent">
              <img src="/images/Journey/building.png" style={{ height: '360px', width: '480px' }} alt="building"/>
            </div>
          </TimelineOppositeContent>
          <TimelineSeparator>
          <TimelineDot color="primary" />
            <TimelineConnector  />
          </TimelineSeparator>
          <TimelineContent>
            <div className="secondPaneContent">
              <p style={{fontWeight: '400', fontSize: '22px'}}>Building the dream (2020-2021)</p>
              <p>
                Partnering with Altar.io, we developed a platform rooted in democratizing knowledge sharing. 
                With a growing team and a groundbreaking “human taxonomy,” 
                we launched our web platform in December 2021, followed by our first Android app, marking milestones in connecting people across the globe.
              </p>
              {/* <p>This was the first step to realising the vision and building Ovvio, a platform that could impact millions around the world.</p>
              <p>We partnered with Alter.io to develop our product and secure our business model.</p>
              <p>By the end of 2020, the prototype and initial screen mock-ups were in progress. 
                  Simultaneously, the team was focused on Ovvio’s core mission: to make knowledge sharing accessible to all, 
                  empowering people to both share their expertise and turn their experiences into opportunities for growth and income.
              </p> */}
            </div>
          </TimelineContent>
        </TimelineItem>
        {/* <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            <div>

            </div>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div className="firstverticalContent">
              <p style={{fontWeight: '400', fontSize: '22px'}}>Milestones achieved (2022-2024)</p>
              <p>Things were gearing up and in full swing to launch Ovvio. Our team was growing, and our diverse team of talent contributed to building the platform and refining our product features. Our key focus at this time was to build our “human taxonomy,” a system for categorising the diverse range of topics and expertise available on Ovvio.</p>
              <p>We also joined social media to increase awareness and introduce Ovvio to the world.</p>
              <p>We hit a major milestone in December 2021 as we opened the doors to the Ovvio web platform. Users could now start connecting to others and share knowledge.</p>
              <p>A few months later, Ovvio’s reach became even wider as we launched our first Android app.</p>
            </div>
          </TimelineContent>
        </TimelineItem> */}
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
            <div className="thirdImageContent">
              <img src="/images/Journey/milestone.png" style={{ height: '360px', width: '480px' }} alt="milestone"/>
            </div>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div className="thirdPaneContent">
              <p style={{fontWeight: '400', fontSize: '22px', textAlign: 'center'}}>Milestones achieved (2022-2024)</p>
              {/* <p>Over the next two years, Ovvio experienced significant growth and achieved several milestones:</p> */}
              <ul>
                <li><p><span style={{ fontWeight: '400' }}>950,000+ app downloads:</span> Demonstrating global demand for authentic knowledge sharing.</p></li>
                <li><p><span style={{ fontWeight: '400' }}>106 countries reached:</span> Connecting diverse communities worldwide.</p></li>
                <li><p><span style={{ fontWeight: '400' }}>110,000+ user signups:</span> With 15% participating as Givers, fostering a vibrant community of knowledge and expertise.</p></li>
                <li><p><span style={{ fontWeight: '400' }}>Resilient technology:</span> Successfully withstanding seven DDoS attacks, ensuring seamless service.</p></li>
                <li><p><span style={{ fontWeight: '400' }}>Innovative insights:</span> Identifying global trends like the Sri Lankan economic crisis through user-driven activity.</p></li>
                {/* <li><p><span style={{ fontWeight: '400' }}>Early detection of trends: </span> Identified global events like the Sri Lankan economic crisis through user activity seeking financial guidance.</p></li> */}
              </ul>
            </div>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent color="text.secondary">
          <div className="fourthImageContent">
              <img src="/images/Journey/decision.png" style={{ height: '380px', width: '480px' }} alt="decision"/>
            </div>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div className="fourthPaneContent">
              <p style={{fontWeight: '400', fontSize: '22px'}}>A decision to reimagine Ovvio (2024)</p>
              <p>
                Closing our consumer-facing platform is not an end—it’s a bold step toward a brighter future. 
                We are returning to the drawing board to reimagine Ovvio through fresh perspectives, leveraging our technology and 
                intellectual property to redefine human expertise in an AI-driven world.
              </p>
              <p>
                Ovvio’s next chapter is one of transformation and leadership, 
                ensuring that human ingenuity remains indispensable and continues to shape the future of 
                connection and knowledge sharing. The best is yet to come.
              </p>
            </div>
          </TimelineContent>  
            
        </TimelineItem>
          
      </Timeline>

      {/* <TimelineDot color="primary" className="finalDot"/> */}
    </ThemeProvider>
  </>
}

function mobileRender() {
  return <>
    <div className="firstverticalContent" style={{paddingTop: '0px'}} >
      <p style={{fontWeight: '400', fontSize: '22px', textAlign: 'center', paddingBottom: '10px'}}>A vision born (2020)</p>
      <div style={{ paddingBottom: '30px' }}>
        <img src="/images/Journey/vision.png" style={{ height: '220px', width: '340px', borderRadius: '5px', border: '0px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} alt="vision"/>
      </div>
      <p>During the pandemic, Ovvio emerged from a pressing need to reconnect people with real human expertise.</p>
      <p>Co-founders Sachin and Shaalin Tandon envisioned a platform where users could find personalized support that technology alone could not provide.</p>
    </div>

    <div className="firstverticalContent" style={{paddingTop: '10px'}}>
        <p style={{fontWeight: '400', fontSize: '22px', textAlign: 'center', paddingBottom: '10px'}}>Building our vision (2020-2021)</p>
        <div style={{ paddingBottom: '30px' }}>
          <img src="/images/Journey/building.png" style={{ height: '220px', width: '340px', borderRadius: '5px', border: '0px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }} alt="vision"/>
        </div>
        <p>
          Partnering with Altar.io, we developed a platform rooted in democratizing knowledge sharing. 
          With a growing team and a groundbreaking “human taxonomy,” we launched our web platform in December 2021, 
          followed by our first Android app, marking milestones in connecting people across the globe.
        </p>
    </div>
    <div className="firstverticalContent" style={{paddingTop: '10px'}}>
      <p style={{fontWeight: '400', fontSize: '22px', textAlign: 'center', paddingBottom: '10px'}}>Milestones achieved (2022-2024)</p>
      <div style={{ paddingBottom: '30px' }}>
        <img src="/images/Journey/milestone.png" style={{ height: '220px', width: '340px', borderRadius: '5px', border: '0px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} alt="vision"/>
      </div>
      <ul>
        <li><p><span style={{ fontWeight: '400' }}>950,000+ app downloads:</span> Demonstrating global demand for authentic knowledge sharing.</p></li>
        <li><p><span style={{ fontWeight: '400' }}>106 countries reached</span> Connecting diverse communities worldwide.</p></li>
        <li><p><span style={{ fontWeight: '400' }}>110,000+ user signups:</span> With 15% participating as Givers, fostering a vibrant community of knowledge and expertise.</p></li>
        <li><p><span style={{ fontWeight: '400' }}>Resilient technology:</span> Successfully withstanding seven DDoS attacks, ensuring seamless service.</p></li>
        <li><p><span style={{ fontWeight: '400' }}>Innovative insights:</span> Identifying global trends like the Sri Lankan economic crisis through user-driven activity.</p></li>
        {/* <li><p><span style={{ fontWeight: '400' }}>Early detection of trends: </span> Identified global events like the Sri Lankan economic crisis through user activity seeking financial guidance.</p></li> */}
      </ul>
    </div>
    <div className="firstverticalContent" style={{paddingTop: '10px'}}>
      <p style={{fontWeight: '400', fontSize: '22px', textAlign: 'center', paddingBottom: '10px'}}>A decision to reimagine Ovvio (2024)</p>
      <div style={{ paddingBottom: '30px' }}>
        <img src="/images/Journey/decision.png" style={{ height: '220px', width: '340px', borderRadius: '5px', border: '0px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}} alt="vision"/>
      </div>
      <p>
        Closing our consumer-facing platform is not an end—it’s a bold step toward a brighter future. 
        We are returning to the drawing board to reimagine Ovvio through fresh perspectives, 
        leveraging our technology and intellectual property to redefine human expertise in an AI-driven world.
      </p>
      <p>
        Ovvio’s next chapter is one of transformation and leadership, ensuring that 
        human ingenuity remains indispensable and continues to shape the future of connection and knowledge sharing. 
        The best is yet to come.
      </p>
    </div>
    {/* <p style={{paddingBottom: '300px'}}></p> */}
  </>
}

export default function Journey() {
  return (
    <>  
      <div style={{textAlign: 'center'}} >
        <h1 className="journeytitle"> Ovvio today & tomorrow </h1>
        {/* <h2 className="subtitle"> Where we are today and the road ahead </h2> */}
      </div>
      <div className="container">
        <div className="firstverticalContent">
          <p style={{fontWeight: '400', fontSize: '22px'}}>The Ovvio app</p>
          <p>
            Though the Ovvio app is no longer active, its legacy endures as a testament to the 
            power of meaningful human connections and the wisdom shared within its community.
            It demonstrated the unparalleled value of human expertise in a rapidly evolving digital world.
          </p>
          <p style={{fontWeight: '400', fontSize: '22px'}}>Ovvio: Evolving for the future</p>
          <p>
            Our journey continues as we repurpose Ovvio’s innovative solutions to align with emerging operating models and complementary technologies. 
            In an era where AI dominates, we believe the human touch holds a unique and irreplaceable value. 
            By focusing on growth and partnerships, we aim to create solutions that elevate human expertise to remain a step ahead of AI.
          </p>
        </div>
        <div style={{textAlign: 'center'}}>
          <h1 className="title" style={{paddingTop: '20px'}}> Our journey so far… </h1>
        </div>
        {  isMobile ?
          mobileRender()
          : desktopRender()
        } 
        <div style={{paddingBottom: '30px'}}></div>
      </div>
    </>
  )
}
